define('nypr-election-countdown/components/election-countdown', ['exports', 'nypr-election-countdown/templates/components/election-countdown', 'nypr-countdown/components/count-down'], function (exports, _electionCountdown, _countDown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _countDown.default.extend({
    layout: _electionCountdown.default,
    classNames: ['election-countdown'],
    tagName: 'div',

    // put this in config/environment.js when import error gets resolved
    calendarLink: '/assets/midterms.ics',

    electionDayEveStart: '2018-11-05T00:00:00.000-05:00',
    electionDayStart: '2018-11-06T00:00:00.000-05:00',
    electionDayEnd: '2018-11-07T00:00:00.000-05:00'
  });
});