define('ember-changeset/utils/assign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = pureAssign;


  var assign = Ember.assign || Object.assign || _assign;

  function _assign(origin) {
    for (var _len = arguments.length, sources = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      sources[_key - 1] = arguments[_key];
    }

    return sources.reduce(function (acc, source) {
      return Ember.merge(acc, source);
    }, Ember.merge({}, origin));
  }

  function pureAssign() /*: Array<Object> */ /*: Object */{
    for (var _len2 = arguments.length, objects = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      objects[_key2] = arguments[_key2];
    }

    return assign.apply(undefined, [{}].concat(objects));
  }
});