define('nypr-auth/adapters/user', ['exports', 'ember-get-config', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberGetConfig, _emberData, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var keys = Object.keys;
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    authorize: function authorize(xhr) {
      var headers = this.get('session').authorize({});
      for (var h in headers) {
        xhr.setRequestHeader(h, headers[h]);
      }
    },

    host: _emberGetConfig.default.authAPI,
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      if (/createRecord|updateRecord|deleteRecord/.test(requestType)) {
        return this.host + '/v1/user';
      } else if (requestType.startsWith('query')) {
        delete query.me;
        return this.host + '/v1/session';
      }
    },


    // conform with JSON merge patch strategy: "only send what you need"
    // https://tools.ietf.org/html/rfc7396
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      var changed = keys(snapshot.record.changedAttributes()).map(function (key) {
        return serializer.attrs[key] || serializer.keyForAttribute(key);
      });

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      keys(data).forEach(function (k) {
        if (!changed.includes(k)) {
          delete data[k];
        }
      });

      return this.ajax(url, 'PATCH', { data: data });
    },
    createRecord: function createRecord(store, type, _ref) {
      var record = _ref.record,
          adapterOptions = _ref.adapterOptions;

      // at this point we're still unauthenticated, so we need to manually add
      // required X-Provider and Authorization headers for sign up via third-
      // party providers e.g. facebook
      if (adapterOptions && adapterOptions.provider) {
        this.set('headers', {
          'X-Provider': adapterOptions.provider,
          'Authorization': 'Bearer ' + record.get('providerToken')
        });
      } else {
        this.set('headers', undefined);
      }
      return this._super.apply(this, arguments);
    }
  });
});