define('nypr-audio-services/components/listen-button', ['exports', 'nypr-audio-services/templates/components/listen-button', 'ember-diff-attrs'], function (exports, _listenButton, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var STATES = {
    PLAYING: 'is-playing',
    PAUSED: 'is-paused',
    LOADING: 'is-loading'
  };

  var EXPANDABLE_BUTTONS = /(blue|gray|red)-(minion|boss)|white-hollow-block/;

  exports.default = Ember.Component.extend({
    layout: _listenButton.default,
    dj: Ember.inject.service(),
    disabled: Ember.computed.not('dj.isReady'),
    playButtonClickedAction: function playButtonClickedAction() {},

    isCurrentSound: Ember.computed('dj.currentContentId', 'itemPK', function () {
      return Ember.get(this, 'itemPK') === Ember.get(this, 'dj.currentContentId');
    }),

    isPlaying: Ember.computed.and('dj.isPlaying', 'isCurrentSound'),
    _hifiPaused: Ember.computed.not('dj.isPlaying'),
    isPaused: Ember.computed.and('_hifiPaused', 'isCurrentSound'),
    isLoading: Ember.computed('isCurrentSound', 'buttonLoading', 'dj.{currentSound.isLoading,currentlyLoadingIds}', function () {
      var currentlyLoadingIds = Ember.A(Ember.getWithDefault(this, 'dj.currentlyLoadingIds', []));

      return Ember.get(this, 'buttonLoading') || currentlyLoadingIds.includes(String(Ember.get(this, 'itemPK'))) || Ember.get(this, 'isCurrentSound') && Ember.get(this, 'dj.currentSound.isLoading');
    }),

    isExpandable: Ember.computed.match('type', EXPANDABLE_BUTTONS),
    'aria-label': Ember.computed.readOnly('title'),
    'data-test-selector': 'listen-button',

    tagName: 'button',
    classNames: ['listen-button', 'gtm__click-tracking'],
    classNameBindings: ['isHovering', 'type', 'isCurrentSound', 'isErrored', 'playState', 'isCurrentSound', 'isLive'],
    attributeBindings: ['aria-label', 'title', 'disabled', 'data-test-selector', 'style', 'data-action', 'data-label'],

    // override in the template for streams and other action types
    'data-action': Ember.computed('playContext', function () {
      return 'Clicked Play/Pause On Demand: ' + this.get('playContext');
    }),
    'data-label': Ember.computed('itemTitle', 'itemShow', function () {
      return this.get('itemTitle') + ' | ' + this.get('itemShow');
    }),

    title: Ember.computed('itemTitle', function () {
      return 'Listen to ' + Ember.get(this, 'itemTitle');
    }),

    style: Ember.computed('width', function () {
      var width = Ember.get(this, 'width');
      return width ? Ember.String.htmlSafe('width: ' + width + 'px;') : null;
    }),

    playState: Ember.computed('isPlaying', 'isPaused', 'isLoading', 'wasMeasured', 'isExpandable', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'wasMeasured', 'isExpandable'),
          wasMeasured = _EmberGetProperties.wasMeasured,
          isExpandable = _EmberGetProperties.isExpandable;

      if (isExpandable && !wasMeasured) {
        return STATES.PAUSED; // consider it paused until we measure so we get full width of natural state
      }

      if (Ember.get(this, 'isLoading')) {
        return STATES.LOADING;
      } else if (Ember.get(this, 'isPlaying')) {
        return STATES.PLAYING;
      } else if (Ember.get(this, 'isPaused')) {
        return STATES.PAUSED;
      } else {
        return STATES.PAUSED; // JIC
      }
    }),

    width: Ember.computed('playState', 'contentWidth', 'isExpandable', function () {
      if (typeof FastBoot !== 'undefined' || !this.element || !Ember.get(this, 'isExpandable')) {
        return false;
      }

      var state = Ember.get(this, 'playState');
      if (state === STATES.PLAYING || state === STATES.LOADING) {
        return Math.ceil(this.element.getBoundingClientRect().height); // make it a circle, set width = height
      } else {
        return Ember.get(this, 'contentWidth');
      }
    }),

    didUpdateAttrs: (0, _emberDiffAttrs.default)('isLive', function (changedAttrs) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      if (typeof FastBoot !== 'undefined' || !this.element || !Ember.get(this, 'isExpandable')) {
        return false;
      }
      var updateSize = this.get('isLive') || changedAttrs && changedAttrs.isLive && changedAttrs.isLive[0];

      if (updateSize) {
        Ember.run.schedule('afterRender', this, function () {
          var contentWidth = _this.element.scrollWidth + parseInt(_this.$().css('paddingLeft'), 10) + parseInt(_this.$().css('paddingRight'), 10);
          Ember.set(_this, 'contentWidth', contentWidth);
        });
      }
    }),

    didRender: function didRender() {
      var _this2 = this;

      var _EmberGetProperties2 = Ember.getProperties(this, 'wasMeasured', 'isExpandable'),
          wasMeasured = _EmberGetProperties2.wasMeasured,
          isExpandable = _EmberGetProperties2.isExpandable;

      if (isExpandable && !wasMeasured) {
        Ember.run.schedule('afterRender', this, function () {
          var contentWidth = Math.ceil(_this2.element.getBoundingClientRect().width);
          Ember.set(_this2, 'wasMeasured', true);
          Ember.set(_this2, 'contentWidth', contentWidth);
        });
      }
    },
    play: function play() {
      var _this3 = this;

      var playContext = Ember.get(this, 'playContext');
      var itemPk = Ember.get(this, 'itemPK');
      var metadata = { fromClick: true };
      Ember.set(this, 'buttonLoading', true);
      Ember.get(this, 'dj').play(itemPk, { playContext: playContext, metadata: metadata }).then(function () {
        Ember.set(_this3, 'buttonLoading', false);
      }).catch(function () {
        Ember.set(_this3, 'buttonLoading', false);
        Ember.set(_this3, 'isErrored', true);
      });
    },
    click: function click() {
      var dj = Ember.get(this, 'dj');
      if (Ember.get(this, 'isPlaying')) {
        dj.pause();
      } else {
        this.play();
        this.playButtonClickedAction(event);
      }
    },
    mouseLeave: function mouseLeave() {
      Ember.set(this, 'isHovering', false);
    },
    mouseEnter: function mouseEnter() {
      Ember.set(this, 'isHovering', true);
    }
  });
});