define('nypr-player/components/nypr-player/progress-meter', ['exports', 'nypr-player/templates/components/nypr-player/progress-meter', 'nypr-player/utils/touch-utils'], function (exports, _progressMeter, _touchUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _progressMeter.default,
    isLoaded: Ember.computed.bool('duration'),
    isHovering: false,
    isDragging: false,
    isTouching: false,
    classNames: ['nypr-player-progress'],
    classNameBindings: ['isHovering', 'isDragging', 'isTouching', 'isLoaded'],
    downloadedPercentage: Ember.computed('downloaded', function () {
      var downloaded = Ember.get(this, 'downloaded');
      return Ember.String.htmlSafe('width: ' + downloaded * 100 + '%;');
    }),
    playedPercentage: Ember.computed('position', function () {
      var position = Ember.get(this, 'position');
      var duration = Ember.get(this, 'duration');
      return Ember.String.htmlSafe('width: ' + position / duration * 100 + '%;');
    }),
    playheadPosition: Ember.computed('isDragging', 'isTouching', 'handlePosition', 'position', 'duration', function () {
      var p = void 0;

      var _EmberGetProperties = Ember.getProperties(this, 'isDragging', 'isTouching', 'handlePosition', 'position', 'duration'),
          isDragging = _EmberGetProperties.isDragging,
          isTouching = _EmberGetProperties.isTouching,
          handlePosition = _EmberGetProperties.handlePosition,
          position = _EmberGetProperties.position,
          duration = _EmberGetProperties.duration;

      if (isDragging || isTouching) {
        p = handlePosition;
      } else {
        p = position / duration;
      }
      return Ember.String.htmlSafe('left : ' + p * 100 + '%;');
    }),

    handlePosition: 0,

    mouseDown: function mouseDown(e) {
      // We only want left clicks
      var isLeftClick = e.which === 1;
      // Sometimes touch events which aren't defaultPrevented send
      // fake MouseEvents.  We handle TouchEvents separately so
      // we don't want these
      var isRealMouse = !(0, _touchUtils.isSimulatedMouseEvent)(e.originalEvent);

      if (Ember.get(this, 'isLoaded') && isLeftClick && isRealMouse) {
        this._updateAudioPosition(e);
        if (e.target.classList.contains('js-nypr-player-progress-playhead')) {
          this._startDragging();
          // prevent dragging and selecting
          e.preventDefault();
        }
      }
    },
    mouseUp: function mouseUp() {
      this._cancelDragging();
    },
    mouseEnter: function mouseEnter() {
      Ember.set(this, 'isHovering', true);
    },
    mouseMove: function mouseMove(e) {
      if (!(0, _touchUtils.isSimulatedMouseEvent)(e)) {
        // prevent dragging and selecting
        e.preventDefault();
        this._updateHandlePosition(e);
      }
    },
    mouseLeave: function mouseLeave() {
      Ember.set(this, 'isHovering', false);
      this._cancelDragging();
    },
    touchStart: function touchStart(e) {
      // prevent emulated mouse events
      e.preventDefault();
      if (Ember.get(this, 'isLoaded') && e.target.classList.contains('js-nypr-player-progress-playhead')) {
        var touch = e.originalEvent.changedTouches[0];
        this._updateAudioPosition(touch);
        Ember.set(this, 'isTouching', true);
        this._startDragging(touch);
      }
    },
    touchEnd: function touchEnd(e) {
      // prevent emulated mouse events
      e.preventDefault();
      if (Ember.get(this, 'isLoaded') && e.target.classList.contains('js-nypr-player-progress-playhead')) {
        var touch = e.originalEvent.changedTouches[0];
        this._updateAudioPosition(touch);
        Ember.set(this, 'isTouching', false);
        this._cancelDragging();
      }
    },
    touchCancel: function touchCancel(e) {
      // prevent emulated mouse events
      e.preventDefault();
      Ember.set(this, 'isTouching', false);
      this._cancelDragging();
    },
    _startDragging: function _startDragging(touch) {
      var _this = this;

      Ember.set(this, 'isDragging', true);
      if (touch) {
        this.$().on('touchmove', Ember.run.bind(this, function (e) {
          // prevent touch scrolling
          e.preventDefault();
          var event = e.originalEvent;
          var movedTouch = (0, _touchUtils.findTouchById)(event.touches, touch.identifier);
          if (movedTouch) {
            _this._updateAudioPosition(movedTouch);
          }
        }));
      } else {
        this.$().on('mousemove', Ember.run.bind(this, function (e) {
          // prevent dragging and selecting
          e.preventDefault();
          _this._updateAudioPosition(e);
        }));
      }
    },
    _cancelDragging: function _cancelDragging() {
      Ember.set(this, 'isDragging', false);
      this.$().off('touchmove');
      this.$().off('mousemove');
    },
    _updateHandlePosition: function _updateHandlePosition(event) {
      if (event.pageX) {
        var offset = this.$('.js-nypr-player-progress-bg').offset();
        var p = void 0;
        if (event.pageX < offset.left) {
          p = 0;
        } else if (event.pageX > offset.left + this.$('.js-nypr-player-progress-bg').width()) {
          p = 1;
        } else {
          p = (event.pageX - offset.left) / this.$('.js-nypr-player-progress-bg').width();
        }
        Ember.set(this, 'handlePosition', p);
        return p;
      }
    },
    _updateAudioPosition: function _updateAudioPosition(event) {
      var p = this._updateHandlePosition(event);
      Ember.get(this, 'setPosition')(p);
    }
  });
});