define('ember-router-scroll/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({
    scheduler: Ember.inject.service('scheduler'),
    service: Ember.inject.service('router-scroll'),

    isFastBoot: Ember.computed(function () {
      var fastboot = Ember.getOwner(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }),

    willTransition: function willTransition() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      Ember.get(this, 'service').update();
    },
    didTransition: function didTransition(transitions) {
      var _this = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [transitions].concat(_toConsumableArray(args)));

      if (Ember.get(this, 'isFastBoot')) {
        return;
      }

      var delayScrollTop = Ember.get(this, 'service.delayScrollTop');

      if (!delayScrollTop) {
        Ember.run.scheduleOnce('render', this, function () {
          return _this.updateScrollPosition(transitions);
        });
      } else {
        // as described in ember-app-scheduler, this addon can be used to delay rendering until after First Meaningful Paint.
        // If you loading your routes progressively, this may be a good option to delay scrollTop until the remaining DOM elements are painted.
        this.get('scheduler').scheduleWork('afterContentPaint', function () {
          _this.updateScrollPosition(transitions);
        });
      }
    },
    updateScrollPosition: function updateScrollPosition(transitions) {
      var lastTransition = transitions[transitions.length - 1];

      var routerPath = void 0;
      if (typeof Ember.get(lastTransition, 'handler._router') !== 'undefined') {
        routerPath = 'handler._router';
      } else {
        routerPath = 'handler.router';
      }
      var url = Ember.get(lastTransition, routerPath + '.currentURL');
      var hashElement = url ? document.getElementById(url.split('#').pop()) : null;

      var scrollPosition = void 0;

      if (url && url.indexOf('#') > -1 && hashElement) {
        scrollPosition = { x: hashElement.offsetLeft, y: hashElement.offsetTop };
      } else {
        scrollPosition = Ember.get(this, 'service.position');
      }
      var scrollElement = Ember.get(this, 'service.scrollElement');

      var preserveScrollPosition = Ember.get(lastTransition, 'handler.controller.preserveScrollPosition');

      if (!preserveScrollPosition) {
        if ('window' === scrollElement) {
          window.scrollTo(scrollPosition.x, scrollPosition.y);
        } else if ('#' === scrollElement.charAt(0)) {
          var element = document.getElementById(scrollElement.substring(1));

          if (element) {
            element.scrollLeft = scrollPosition.x;
            element.scrollTop = scrollPosition.y;
          }
        }
      }
    }
  });
});