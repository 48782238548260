define('nypr-metrics/services/data-layer', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var DEFAULT_STREAM_STORY = '[no title available: BBC or NPR broadcast]';

  exports.default = Ember.Service.extend({
    push: function push(key, value) {
      var dataLayer = this.getDataLayer();
      if ((typeof key === 'undefined' ? 'undefined' : _typeof(key)) === 'object') {
        dataLayer.push(key);
      } else {
        dataLayer.push(_defineProperty({}, key, value));
      }
    },
    clear: function clear() {
      var dataLayer = this.getDataLayer();
      var toClear = {};

      for (var _len = arguments.length, keys = Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }

      keys.forEach(function (k) {
        return toClear[k] = null;
      });
      dataLayer.push(toClear);
    },
    trigger: function trigger(eventName) {
      this.push('event', eventName);
    },
    setForType: function setForType(type, instance) {
      var dataLayer = this.getDataLayer();
      var values = void 0;
      switch (type) {
        case 'story':
          values = this._valuesForStory(instance);
          break;
        case 'articlechannel':
        case 'series':
        case 'show':
        case 'tag':
          values = this._valuesForContainer(instance);
          break;
        default:
          values = {};
      }

      dataLayer.push(values);
    },
    clearForType: function clearForType(type) {
      var dataLayer = this.getDataLayer();
      switch (type) {
        case 'story':
          dataLayer.push({
            'Viewed Authors': null,
            'Viewed Date Published': null,
            'Viewed Has Audio': null,
            'Viewed ID': null,
            'Viewed Item Type': null,
            'Viewed NPR ID': null,
            'Viewed Org ID': null,
            'Viewed Show Title': null,
            'Viewed Container Slug': null,
            'Viewed Story Major Tags': null,
            'Viewed Story Template': null,
            'Viewed Story Title': null,
            'Viewed Story Series': null,
            'Viewed Story Tags': null,
            'Viewed Story Word Count': null
          });
          break;
        case 'show':
          dataLayer.push({
            'Viewed Article Channel Title': null,
            'Viewed Has Audio': null,
            'Viewed ID': null,
            'Viewed Item Type': null,
            'Viewed NPR ID': null,
            'Viewed Org ID': null,
            'Viewed Tag Title': null,
            'Viewed Show Title': null,
            'Viewed Container Slug': null,
            'Viewed Series Title': null,
            'Viewed Story Major Tags': null,
            'Viewed Story Tags': null,
            'Viewed Story Word Count': null
          });
          break;
      }
    },
    setLoggedIn: function setLoggedIn(state) {
      if (![true, false].includes(state)) {
        return;
      }
      var dataLayer = this.getDataLayer();
      dataLayer.push({
        'Logged In': state.toString()
      });
    },
    setMemberStatus: function setMemberStatus(status) {
      if (!['Nonmember', 'One-Time Donor', 'Sustainer'].includes(status)) {
        return;
      }

      var dataLayer = this.getDataLayer();
      dataLayer.push({
        'Member Status': status
      });
    },
    setPageTitle: function setPageTitle(title) {
      var dataLayer = this.getDataLayer();
      dataLayer.push({ 'Page Title': title });
    },
    sendPageView: function sendPageView() {
      var dataLayer = this.getDataLayer();
      dataLayer.push({ event: 'Page View' });
    },
    send404: function send404() {
      var dataLayer = this.getDataLayer();
      dataLayer.push({ event: '404' });
    },
    audioTracking: function audioTracking(type, soundObject) {
      if (!['play', 'pause', 'resume', 'end', 'schedule'].includes(type)) {
        return;
      }
      var dataLayer = this.getDataLayer();
      var event = this._audioEventForType(soundObject);
      event['Audio Playback State'] = type;
      dataLayer.push(event);
    },
    errorTracking: function errorTracking(event, path) {
      var dataLayer = this.getDataLayer();
      dataLayer.push({
        event: event,
        'Bad URL Path': path
      });
    },
    audioErrorTracking: function audioErrorTracking(errorType, errorDetails) {
      var dataLayer = this.getDataLayer();
      dataLayer.push({
        event: 'audioError',
        errorType: errorType,
        errorDetails: errorDetails
      });
    },
    getDataLayer: function getDataLayer() {
      if (!window.dataLayer) {
        if (!window.runningTests) {
          if (typeof document !== 'undefined') {
            console.warn('No global dataLayer available'); // eslint-disable-line
          }
        }
        return [];
      } else {
        return window.dataLayer;
      }
    },
    _valuesForStory: function _valuesForStory(story) {
      var DEFAULT_NPR_VALS = ['NYPR'].concat(_toConsumableArray(Array(7)), [_emberGetConfig.default.siteName, null, document.title], _toConsumableArray(Array(3)));

      var values = {};
      var nprVals = Ember.get(story, 'nprAnalyticsDimensions') || DEFAULT_NPR_VALS;

      values['Viewed Authors'] = Ember.get(story, 'appearances.authors').map(function (a) {
        return a.name;
      }).join(', ');
      values['Viewed Date Published'] = Ember.get(story, 'newsdate');
      values['Viewed Show Title'] = Ember.get(story, 'showTitle') || Ember.get(story, 'channelTitle') || 'NPR Article';
      values['Viewed Container Slug'] = Ember.get(story, 'show') || Ember.get(story, 'channel');
      values['Viewed Story Title'] = Ember.get(story, 'title');
      values['Viewed Story Template'] = Ember.get(story, 'template');
      values['Viewed Story Series'] = Ember.get(story, 'series').map(function (s) {
        return s.title;
      }).join(', ');

      // for NPR
      values['Viewed Item Type'] = Ember.get(story, 'itemType');
      values['Viewed ID'] = Ember.get(story, 'cmsPK').toString();
      values['Viewed Story Major Tags'] = nprVals[4];
      values['Viewed Story Tags'] = Ember.get(story, 'tags').join(',');
      values['Viewed Org ID'] = nprVals[7];
      values['Viewed Has Audio'] = nprVals[9];
      values['Viewed Story Word Count'] = nprVals[12];
      values['Viewed NPR ID'] = nprVals[13];

      return values;
    },
    _valuesForContainer: function _valuesForContainer(container) {
      var values = {};

      switch (Ember.get(container, 'itemType')) {
        case 'show':
          values['Viewed Show Title'] = Ember.get(container, 'title');
          break;
        case 'series':
          values['Viewed Series Title'] = Ember.get(container, 'title');
          break;
        case 'articlechannel':
          values['Viewed Article Channel Title'] = Ember.get(container, 'title');
          break;
        case 'tag':
          values['Viewed Tag Title'] = Ember.get(container, 'title');
      }

      values['Viewed Container Slug'] = Ember.get(container, 'slug');

      // for NPR
      values['Viewed Item Type'] = Ember.get(container, 'itemType');
      values['Viewed ID'] = Ember.get(container, 'cmsPK').toString();
      values['Viewed Story Major Tags'] = 'none';
      values['Viewed Story Tags'] = 'none';
      values['Viewed Org ID'] = '0';
      values['Viewed Has Audio'] = '0';
      values['Viewed Story Word Count'] = 'none';
      values['Viewed NPR ID'] = 'none';

      return values;
    },
    _audioEventForType: function _audioEventForType(soundObject) {
      var _EmberGet = Ember.get(soundObject, 'metadata'),
          type = _EmberGet.contentModelType,
          model = _EmberGet.contentModel,
          source = _EmberGet.playContext;

      if (!['discover', 'continuous-stream', 'continuous-queue', 'queue', 'history'].includes(source)) {
        source = null;
      }

      switch (type) {
        case 'story':
          // on demand
          return {
            event: 'On Demand Audio Playback',
            'Audio Story Title': Ember.get(model, 'title'),
            'Audio Show Title': Ember.get(model, 'showTitle') || Ember.get(model, 'channelTitle') || 'NPR Article',
            'Audio URL': Ember.get(soundObject, 'url'),
            'Audio Playback Source': source
          };
        case 'stream':
          return {
            event: 'Livestream Audio Playback',
            'Audio Story Title': Ember.get(model, 'currentShow.episodeTitle') || Ember.get(model, 'currentPiece') || DEFAULT_STREAM_STORY,
            'Audio Show Title': Ember.get(model, 'currentShow.showTitle'),
            'Audio Stream Title': Ember.get(model, 'name'),
            'Audio URL': Ember.get(soundObject, 'url'),
            'Audio Playback Source': source
          };
      }
    }
  });
});