define('ember-chimp/lib/default-responses', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    success: 'Almost finished... We need to confirm your email address. To complete the subscription process, please click the link in the email we just sent you.',
    error: 'Oops, something went wrong.  Please try again.',
    invalidError: 'Please enter a valid email.',
    attemptsError: "Too many subscribe attempts for this email address. Please try again in about 5 minutes."
  };
});